import React, {useState} from 'react'
import './services.css'

const Services = () => {
  const servicesData = [
    {
      title: 'Gestion de projet',
      picture: 'Meeting',
      details: {
        title: 'Gestion de projet',
        details: ["Etre un bon chef de projet", "Gestion du périmètre","Plannification du projet", "Gestion de la qualité","Gestion des coûts", "Gestion des resources","Gestion des risques", "Choisir son framework","Conduite du changement", "Agilité et méthode Scrum vs Kanban","Les acteurs", "Les cérémonies Agiles", "L'organisation du travail", "La communication", "Le framework SAFe", "Les outils"]
      }
    },
    {
      title: 'Outils & formation Excel',
      picture: 'Excel',
      details: {
        title: 'Création d\'outils & formation Excel',
        details: ["Automatisation en VBA", "Connexion à des bases de données", "Traitement de données", "Compléments Excel personnalisés en JS", "Dashboarding sur Power BI", "Plannification", "Gestion des finances", "Gestion d'articles", "Ventes et facturation", "Formation: Consolider les bases", "Un couteau suisse de 50 formules", "Savoir se documenter", "Maîtriser les tableaux croisés dynamiques", "Macros et formulaires", "Organiser son code en VBA", "Préparation à des projets complexes"],
        brochure: "../../assets/brochure_excel_isomeosconsulting.pdf"
      }
    },
    {
      title: 'Agilité comportementale',
      picture: 'Hands',
      details: {
        title: 'Agilité comportementale & Leadership',
        details: ["Test MBTI", "Intelligence émotionnelle", "Langage relationnel", "Profils de communication", "Cartographie d'équipe", "Management du stress et PNL", "Culture organisationnelle", "Leadership Agile", "Etre un bon leader", "Développer sa vision", "Management du changement", "Le modèle de Kotter vs Beer"]
      }
    },
    {
      title: 'Stratégie bas-carbone',
      picture: 'Foretbrume',
      details: {
        title: 'Bilan carbone & Stratégie',
        details: ["Ateliers de sensibilisation", "Bilan carbone certifié", "Périmètrisation: scope 1, 2 ou 3", "Automatisation collecte des données", "Analyse du bilan", "Plan d'actions", "Analyse d'impact sur la société", "Mise en place et gestion de projet", "Stratégie - organisation & investissements", "Projets de compensation carbone"]
      }
    }
  ]
  var [isShown, setIsShown] = useState({index: []});
  return (
    <div id='services' className='isomeos__services'>
      <div className='isomeos__services-title'>
        <h1>Nos services</h1>
      </div>
      <div className='isomeos__services-container'>
        <div className='isomeos__services-container_widgetcontainer'>
          {servicesData.map((item, index) => (
            <div className='isomeos__services-container_widgetcontainer-child'
              onClick={() => {
                var flag = false;
                for (var i = isShown.index.length - 1; i >= 0; i--) {
                  if (isShown.index[i] === index) {
                  flag=true;
                  }
                }
                if (flag==false) {
                  isShown.index = [];
                  isShown.index.push(index);
                  setIsShown({index: isShown.index});
                } else {
                  isShown.index = [];
                  setIsShown({index: isShown.index});
                }
              }}>
              <div className='isomeos__services-container_widgetcontainer-child_title'>
                <p>{item.title}</p>
              </div>
              <div className='isomeos__services-container_widgetcontainer-child_picture'>
                <img src={require('../../assets/' + item.picture + '.png')}/>
              </div>
            </div>
          ))}
        </div>
        <div className='isomeos__services-container_link'>
          {servicesData.map((item, index) => (
            <div>
              {isShown.index.includes(index) && (
                <div className='isomeos__services-container_link-child'></div>
              )}
            </div>
          ))}
        </div>
        {servicesData.map((item, index) => (
          <div>
          {isShown.index.includes(index) && (
            <div className='isomeos__services-container_detailscontainer'>
              <div className='isomeos__services-container_detailscontainer-title'>
                <p>{item.details.title}</p>
              </div>
              <div className='isomeos__services-container_detailscontainer-details'>
                {item.details.details.map((text, index) => (
                  <li>{text}</li>
                ))}
              </div>
            </div>
          )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services