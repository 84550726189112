import React from 'react'
import './expertise.css'
import checkmark from '../../assets/Checkmark.png'

const Expertise = () => {
  const expertiseData = [
    {
      title: "Gestion de projet",
      details: ["Agile", "Scrum vs Kanban", "Conduite du changement", "Outils : Azure DevOps, Jira, ..."]
    },
    {
      title: "Suite Microsoft Office",
      details: ["Excel et VBA", "Power BI", "Base Access et SQL", "Formation"]
    },
    {
      title: "Coaching",
      details: ["Agilité comportementale", "Leadership", "Vision", "Organisation"]
    },
    {
      title: "Impact carbone",
      details: ["Bilan carbone", "Stratégie", "Cadrage et suivi"]
    }
  ]
  return (
    <div className='isomeos__expertise'>
      <div className='isomeos__expertise-title'>
        <h1>Notre expertise</h1>
      </div>
      <div className='isomeos__expertise-container'>
        {expertiseData.map((item, index) => (
          <div className='isomeos__expertise-container_item'>
            <div className='isomeos__expertise-container_item-title'>
              <p>{item.title}</p>
            </div>
            <div className='isomeos__expertise-container_item-detailscontainer'>
              {item.details.map((text, indextext) => (
                <div className='isomeos__expertise-container_item-detailscontainer_item'>
                  <div className='isomeos__expertise-container_item-detailscontainer_item-icon'>
                    <img src={checkmark}/>
                  </div>
                  <div className='isomeos__expertise-container_item-detailscontainer_item-text'>
                    <p>{text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Expertise