import React, {useState} from 'react'
import './consulting_approach.css'
import expandicon from '../../assets/Expandicon.svg'

const approachData = [
  {
    title: 'Une expertise hors temps de travail',
    details: 'Dans un format particulier, pour concilier activité entrepreneuriale et salariée, tout échange avec nos clients s\'effectue entre 18h et 23h la semaine ainsi que les week-ends.'
  },
  {
    title: 'Un engagement sans faille "gagnant-gagnant"',
    details: 'Le client est la priorité. L\'accompagnement au coeur de nos valeurs. Chaque mission réussie renforce notre expérience et notre expertise. Nous souhaitons mettre en place un cercle verteux de la réussite et de l\'optimisation de la transformation des entreprises pour un impact social, économique et environnemental positif.'
  },
  {
    title: 'Un paiement sous réserve d\'atteinte des objectifs',
    details: 'Une des façons de prouver notre engagement et notre quête de la qualité est que tout règlement sera demandé une fois la mission réalisée et seulement si elle atteint les objectifs du système de qualimétrie préalablement mis en place ensemble.'
  }
]

const Consulting_approach = () => {
  var [isShown, setIsShown] = useState({index: []});
  var [expandPrice, setExpandPrice] = useState(false);
  return (
    <div className='isomeos__consultingapproach'>
      <div className='isomeos__consultingapproach-title'>
        <h1>Une approche du conseil unique</h1>
      </div>
      <div className='isomeos__consultingapproach-content'>
      <div className='isomeos__consultingapproach-content_container'>
        {approachData.map((item, index) => (
          <div className='isomeos__consultingapproach-container_child'>
            <div className='isomeos__consultingapproach-container_childdisplayed' 
              onClick={() => {
                var flag = false;
                for (var i = isShown.index.length - 1; i >= 0; i--) {
                  if (isShown.index[i] === index) {
                   isShown.index.splice(i, 1);
                   flag=true;
                  }
                }
                if (flag == false) {
                  isShown.index.push(index)
                }
                setIsShown({index: isShown.index});
              }}>
              <div className='isomeos__consultingapproach-container_child-title'>
                <p>{item.title}</p>
              </div>
            </div>
            {isShown.index.includes(index) && (
              <div className='isomeos__consultingapproach-container_childtoshow'>
                <div className='isomeos__consultingapproach-container_child-details'>
                  <p>{item.details}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
    </div>
  )
}

export default Consulting_approach