import React, {useState} from 'react'
import './methodology.css'

const Methodology = () => {
  const methologyData = [
    {
      title: "Préparer la mission",
      details: "Il s’agit, lors d’un entretien préliminaire, de bien définir les besoins et les objectifs de nos clients en recueillant les informations essentielles de l’entreprise : chiffres clefs, évolutions récentes,  enjeux, perspectives… Cela nous permet d’établir un pré-diagnostic qui nous conduit à formaliser la problématique de nos clients et ainsi choisir une approche appropriée et personnalisée."
    },
    {
      title: "Recueil et analyse des données",
      details: "Cette collecte d’informations correspond au lancement effectif de la mission. Elle s’effectue à l’aide d’entretiens, de questionnaires, de brainstorming et de méthodes créatives auprès des personnes clefs de l’entreprise. Nous analysons donc les faits en les distinguant des opinions et jugements de valeur."
    },
    {
      title: "Le diagnostic",
      details: "A l’aide des données recueillies, nous procédons au diagnostic approfondi de la problématique de nos clients."
    },
    {
      title: "Elaboration du plan d'action",
      details: "Toujours en coproduction car il n’y a pas de plan d’action qui vaille sans être partagé, nous élaborons une stratégie comprenant des objectifs SMART (Spécifiques, Mesurables, Ambitieux, Réalistes, Temporels) et des mesures concrêtes. Nous définissons avec nos clients qui fait quoi, comment et selon quel calendrier."
    },
    {
      title: "Assistance à la mise en oeuvre",
      details: "C’est une étape capitale pour nous. Vous nous consultez soit parce que vous manquez de temps pour résoudre une problématique soit parce que vous ne disposez pas des ressources nécessaires en interne. Nous ne nous contentons donc pas de vous remettre un « joli » rapport d’audit mais nous vous accompagnons dans la mise en oeuvre des actions que vous aurez choisies parmi les préconisations effectuées. Cela s’effectue par le biais de formations, d’accompagnements terrain, de rédaction de nouvelles procédures, d’assistance méthodologique, de points de contrôle et de cadrage etc."
    },
    {
      title: "Evaluation des résultats obtenus",
      details: "Sur la base d’un outil spécifiquement conçu pour la mission, nous évaluons avec le(s) responsable(s) au sein de l’entreprise ce que nous avons concrètement apporté à nos clients. Nous nous situons dans une démarche permanente d’amélioration continue et ce, grâce à votre retour. Vous souhaitez des résultats à l’issue de nos interventions, nous y sommes nous aussi attachés en vous garantissant confidentialité et partage."
    },
    {
      title: "Communication des résultats obtenus",
      details: "Les résultats obtenus ne sont profitables à votre entreprise que s’ils sont partagés. Nous choisissons ensemble le meilleur levier pour que les acteurs concernés puissent bénéficier des évolutions initiées."
    }
  ]
  var [isShown, setIsShown] = useState({index: []});
  return (
    <div id='methodology' className='isomeos__methodology'>
      <div className='isomeos__methodology-title'>
        <h1>Notre méthologie</h1>
      </div>
      <div className='isomeos__methodology-container'>
        {methologyData.map((item, index) => (
          <div className='isomeos__methodology-container_child'>
            <div className='isomeos__methodology-container_child-title'
            onClick={() => {
              var flag = false;
              for (var i = isShown.index.length - 1; i >= 0; i--) {
                if (isShown.index[i] === index) {
                 isShown.index.splice(i, 1);
                 flag=true;
                }
              }
              if (flag == false) {
                isShown.index.push(index)
              }
              setIsShown({index: isShown.index});
            }}>
              <p>{item.title}</p>
            </div>
            {isShown.index.includes(index) && (
              <div className='isomeos__methodology-container_child-details scale-up-top'>
                <p>{item.details}</p>
              </div>
            )}
            <div className='isomeos__methodology-container_child-link'></div>
          </div>
          ))}
      </div> 
    </div>
  )
}

export default Methodology