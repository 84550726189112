import React, {useState} from 'react'
import './whoweare.css'
import whoweare_picture from '../../assets/Whoweare_picture.png'
import whowerare_leftbutton from '../../assets/Whoweare_leftbutton.svg'
import whowerare_rightbutton from '../../assets/Whoweare_rightbutton.svg'

const Whoweare = () => {
  var [isShown, setIsShown] = useState(true);
  return (
    <div id='whoweare' className='isomeos__whoweare'>
      <div className='isomeos__whoweare-title'>
        <h1>Qui sommes-nous</h1>
      </div>
      <div className='isomeos__whoweare-subtitle'>
        <p>Product Owner de jour</p>
        <p>Auto-entrepreneur de nuit</p>
      </div>
      <div className='isomeos__whoweare-container'>
        <div className='isomeos__whoweare-container_picture'>
          <img src={whoweare_picture}/>
        </div>
        <div className='isomeos__whoweare-container_detailscontainer'>
          {isShown
            ? <div className='isomeos__whoweare-container_detailscontainer-details'>
                <div className='isomeos__whoweare-container_detailscontainer-details_text'>
                  <p>Product Owner chez Schneider Electric depuis 4 ans et responsable de plusieurs offres de notre plateforme IIoT end-to-end, j’ai par ailleurs participé à la transformation Agile de notre Line of Business et apporté de nombreux outils de gestion de projet.</p>
                  <p>J’ai été amené à gérer des équipes multi-sites pluriculturelles et à animer des ateliers d’agilité comportementale pour optimiser les échanges internes et externes aux équipes.</p>
                </div>
                <div className='isomeos__whoweare-container_detailscontainer-details_picture'
                onClick={() => {
                  setIsShown(!isShown)
                }}>
                  <img src={whowerare_rightbutton}/>
                </div>
              </div>
            : <div className='isomeos__whoweare-container_detailscontainer-details'>
                <div className='isomeos__whoweare-container_detailscontainer-details_picture'
                onClick={() => {
                  setIsShown(!isShown)
                }}>
                  <img src={whowerare_leftbutton}/>
                </div>
                <div className='isomeos__whoweare-container_detailscontainer-details_text'>
                  <p>Pour jouer un rôle dans la transformation positive des entreprises et par une approche innovante du conseil, monter d'avantage en compétence sur des sujets importants à mes yeux, j'ai crée Isomeos Consulting.</p>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Whoweare