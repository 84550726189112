import React from 'react'
import './footer.css'
import logo from '../../assets/LogoIsomeosTransparent.png'
import mail from '../../assets/Mail.png'
import phone from '../../assets/Phone.png'
import linkedin from '../../assets/LinkedIn.png'

const Footer = () => {
  return (
    <div className='isomeos__footer'>
      <div className='isomeos__footer-leftcontainer'>
        <div className='isomeos__footer-logo'>
          <img src={logo}/>
        </div>
        <div className='isomeos__footer-email'>
          <div className='isomeos__footer-email_icon'>
            <img src={mail}/>
          </div>
          <div className='isomeos__footer-email_text'>
            <p>guillaume.roth@isomeos-consulting.fr</p>
          </div>
        </div>
        <div className='isomeos__footer-phone'>
          <div className='isomeos__footer-phone_icon'>
            <img src={phone}/>
          </div>
          <div className='isomeos__footer-phone_text'>
            <p>06 78 74 16 68</p>
          </div>
        </div>
      </div>
      <div className='isomeos__footer-linkedinicon'>
        <a href='https://www.linkedin.com/in/guillaumeroth' target="_blank"><img src={linkedin}/></a>
      </div>
    </div>
  )
}

export default Footer