import React, {useState} from 'react'
import './navbar.css'
import logo from '../../assets/LogoIsomeosTransparent.png'
import menu from '../../assets/Menu.svg'
import cross from '../../assets/Cross.svg'

const Navbar = () => {
  var [isShown, setIsShown] = useState(true);
  return (
    <div className='isomeos__navbar'>
      <div className='isomeos__navbar-links_logo'>
          <a href='#home'>
            <img src={logo}/>
            <p>Isomeos Consulting</p>
          </a>
      </div>
      <div className='isomeos__navbar-links_container'>
        <p><a href='#services'>Nos services</a></p>
        <p><a href='#methodology'>Méthodologie</a></p>
        <p><a href='#whoweare'>Qui sommes-nous</a></p>
      </div>
      <div className='isomeos__navbar-menu'>
        {isShown
          ? <div className='isomeos__navbar-menu_icon' onClick={() => setIsShown(false)}>
              <img src={menu}/>
            </div>
          : <div className='isomeos__navbar-menu_icon' onClick={() => setIsShown(true)}>
              <img src={cross}/>
            </div>
        }
        {!isShown && (
          <div className='isomeos__navbar-menu_container slide-left sticky_menu'>
            <p><a href='#services'>Nos services</a></p>
            <p><a href='#methodology'>Méthodologie</a></p>
            <p><a href='#whoweare'>Qui sommes-nous</a></p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar