import React, {useState} from 'react'
import './carbon_strategy.css'
import slideup from '../../assets/SlideUp.png'
import slideuporange from '../../assets/SlideUpOrange.png'

const carbonstrategyData = [
  {
    title: "Bilan Carbone",
    details: ["Périmètrisation", "Collecte des données", "Analyse"]
  },
  {
    title: "Axes de décarbonation",
    details: ["Propositions", "Analyse d'impact", "Mise en place"]
  },
  {
    title: "Projets de compensation",
    details: ["Forestier", "Energies renouvelables", "Utilisation rationnelle de l'énergie"]
  }
]

const Carbon_strategy = () => {
  var [isShown, setIsShown] = useState({index: []});
  return (
    <div className='isomeos__carbonstrategy'>
      <div className='isomeos__carbonstrategy-title'>
        <h1>Stratégie bas-carbone</h1>
      </div>
      <div className='isomeos__carbonstrategy-container'>
        {carbonstrategyData.map((item, index) => (
          <div className='isomeos__carbonstrategy-container_child'>
            {isShown.index.includes(index)
              ? <div className='isomeos__carbonstrategy-container_child-icon'
                  onClick={() => {
                    var flag = false;
                    for (var i = isShown.index.length - 1; i >= 0; i--) {
                      if (isShown.index[i] === index) {
                      flag=true;
                      }
                    }
                    if (flag==false) {
                      isShown.index = [];
                      isShown.index.push(index);
                      setIsShown({index: isShown.index});
                    } else {
                      isShown.index = [];
                      setIsShown({index: isShown.index});
                    }
                  }}>
                  <img src={slideuporange}/>
                </div>
              : <div className='isomeos__carbonstrategy-container_child-icon scale-up-center'
                  onClick={() => {
                    var flag = false;
                    for (var i = isShown.index.length - 1; i >= 0; i--) {
                      if (isShown.index[i] === index) {
                      flag=true;
                      }
                    }
                    if (flag==false) {
                      isShown.index = [];
                      isShown.index.push(index);
                      setIsShown({index: isShown.index});
                    } else {
                      isShown.index = [];
                      setIsShown({index: isShown.index});
                    }
                  }}>
                  <img src={slideup}/>
                </div>
            }
            <div className='isomeos__carbonstrategy-container_child-title'>
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='isomeos__carbonstrategy-detailsnotexpanded'>
        {carbonstrategyData.map((item, index) => (
          <div>
          {isShown.index.includes(index) && (
            <div className='isomeos__carbonstrategy-detailsexpanded'>
              <div className='isomeos__carbonstrategy-detailsexpanded_textcontainer'>
                {item.details.map((text, indextext) => (
                  <div className='isomeos__carbonstrategy-detailsexpanded_textcontainer-text'>
                    <p>{text}</p>
                  </div>
                ))}
                </div>
              <div className='isomeos__carbonstrategy-detailsexpanded_knowmore'>
                <a href="#services"><p>En savoir plus</p></a>
              </div>
            </div>
          )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carbon_strategy