import React from 'react'
import './App.css'
import { Navbar, Header, Consulting_approach, Carbon_strategy, Expertise, Services, Methodology, Whoweare, Footer } from './containers'

const App = () => {
  return (
    <div className='App'>
      <Navbar/>
      <Header/>
      <Consulting_approach/>
      <Carbon_strategy/>
      <Expertise/>
      <Services/>
      <Methodology/>
      <Whoweare/>
      <Footer/>
    </div>
  )
}

export default App